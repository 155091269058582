import React, { useMemo, useState, useEffect } from "react";
import {
  useStripe,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const API_BASE_URL = "https://bound-back-end-e5d00b51b674.herokuapp.com"; // Replace with your actual API base URL

const useOptions = (paymentRequest) => {
  const options = useMemo(
    () => ({
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: "dark",
          height: "48px",
          type: "donate",
        },
      },
    }),
    [paymentRequest]
  );

  return options;
};

const usePaymentRequest = ({ options, onPaymentMethod }) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  useEffect(() => {
    if (stripe && paymentRequest === null) {
      const pr = stripe.paymentRequest(options);
      setPaymentRequest(pr);
    }
  }, [stripe, options, paymentRequest]);

  useEffect(() => {
    let subscribed = true;
    if (paymentRequest) {
      paymentRequest.canMakePayment().then((res) => {
        if (res && subscribed) {
          setCanMakePayment(true);
        }
      });
    }

    return () => {
      subscribed = false;
    };
  }, [paymentRequest]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on("paymentmethod", onPaymentMethod);
    }
    return () => {
      if (paymentRequest) {
        paymentRequest.off("paymentmethod", onPaymentMethod);
      }
    };
  }, [paymentRequest, onPaymentMethod]);

  return canMakePayment ? paymentRequest : null;
};

const PaymentRequestForm = ({
  amount,
  donationType,
  email,
  firstName,
  lastName,
  months,
  message,
  total,
  Processingfeeamt,
  payedFee,
}) => {
  const navigate = useNavigate();
  const stripe = useStripe();

  const endpoint =
    donationType === "one_time"
      ? `${API_BASE_URL}/api/bound/charge`
      : `${API_BASE_URL}/api/bound/subscribe`;

  const paymentRequest = usePaymentRequest({
    options: {
      country: "US",
      currency: "usd",
      total: {
        label: "Donation",
        amount: Math.round(total * 100), // Convert to cents
      },
    },
    onPaymentMethod: async ({ complete, paymentMethod, ...data }) => {
      console.log("[PaymentMethod]", paymentMethod);
      console.log("[Customer Data]", data);

      try {
        // const endpoint = `${API_BASE_URL}/api/charge`;

        const response = await axios.post(endpoint, {
          payment_method: paymentMethod.id,
          amount: Math.round(total * 100),
          total: Math.round(total * 100),
          email: email,
          first_name: firstName,
          last_name: lastName,
          months: months,
          message: message,
          payedFee: payedFee,
          Processingfeeamt: Processingfeeamt,
        });

        if (response.data.success) {
          console.log("Payment successful!");
          complete("success");

          navigate("/success");
        } else {
          console.log("Payment failed. Please try again.");
          complete("fail");

          alert("Payment failed. Please try again.");
        }
      } catch (error) {
        console.error("Error processing payment:", error);
        complete("fail");
        alert("Payment failed. Please try again.");
      }
    },
  });

  const options = useOptions(paymentRequest);

  if (!paymentRequest) {
    return "Loading...";
  }

  // console.log(localPaymentFinalAmount, "localPaymentFinalAmount in google pay");

  return (
    <PaymentRequestButtonElement
      className="PaymentRequestButton"
      options={options}
      onReady={() => {
        console.log("PaymentRequestButton [ready]");
      }}
      onClick={(event) => {
        console.log("PaymentRequestButton [click]", event);
      }}
      onBlur={() => {
        console.log("PaymentRequestButton [blur]");
      }}
      onFocus={() => {
        console.log("PaymentRequestButton [focus]");
      }}
    />
  );
};

export default PaymentRequestForm;
